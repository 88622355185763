<script>
import { PolarArea } from "vue-chartjs";

export default {
  extends: PolarArea,
  props: {
    chartdata: {
      type: Object,
      default: null,
    },
    options: {
      type: Object,
      default: null,
    },
  },
  mounted() {
    this.addPlugin({
        id: "donut",
    });
    this.renderChart(this.chartdata, this.options);
  },
};
</script>

<style lang="sass">
#doughnut-chart
    padding: 0 4% 4% 4%
</style>