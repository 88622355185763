<template>
  <div class="mt-2">
    <today-cards-facebook
      :general_facebook_reports="general_facebook_reports"
      v-if="general_facebook_reports && superAdmin"
    />
    <!-- <total-cards-section
      :facebook_cards_insights="facebook_cards_insights"
      v-if="facebook_cards_insights"
    /> -->

    <v-row
      v-if="
        campaigns_impressions_chart_data &&
        campaigns_clicks_chart_data &&
        age_chart_data
      "
    >
      <v-col cols="6">
        <general-card class="pa-5">
          <h4 class="mb-2">
            Average CPM
            <span class="contrast-color ml-1" v-if="facebook_cards_insights"
              >${{ facebook_cards_insights.average_cpm.toFixed(2) }}
            </span>
          </h4>

          <div class="first-custom-height">
            <line-chart
              v-if="campaigns_impressions_chart_data"
              :chartdata="campaigns_impressions_chart_data"
              :options="impressions_chart_options"
            />
          </div>
        </general-card>
        <general-card class="mt-3 pa-5">
          <h4 class="mb-2">Clicks vs Cost Per Click</h4>

          <div class="first-custom-height">
            <line-chart
              v-if="campaigns_clicks_chart_data"
              :chartdata="campaigns_clicks_chart_data"
              :options="clicks_chart_options"
            />
          </div>
        </general-card>
      </v-col>
      <v-col cols="6">
        <general-card class="pa-5" style="height: 100%">
          <h4 class="mb-2">Age Reach</h4>
          <donut-chart
            v-if="age_chart_data"
            :chartdata="age_chart_data"
            :options="age_chart_options"
          />
        </general-card>
      </v-col>
    </v-row>

    <CampaignOverviewChartFB></CampaignOverviewChartFB>

    <running-cards-section
      :running_campaigns="running_campaigns"
      :paused_campaigns="paused_campaigns"
      :draft_campaigns="draft_campaigns"
      :finished_campaigns="finished_campaigns"
    />

    <v-row v-if="impressions_conversions_chart_data">
      <v-col cols="12">
        <general-card class="pa-5">
          <v-row>
            <v-col
              ><h4 class="mb-2">
                Clicks vs Impresssions
                <!-- <span class="text--disabled ml-7 caption"
                  >26 Aug - 26 Sept</span
                > -->
                <span class="float-end mr-6 percentage">
                  <span class="mr-7">
                    <v-icon color="#F8C76C">mdi-rectangle</v-icon>
                    Clicks</span
                  >
                  <v-icon color="#4F92ED">mdi-rectangle</v-icon>
                  Impressions
                  <!-- <v-icon small class="ml-3 contrast-color"
                    >mdi-information</v-icon
                  > -->
                </span>
              </h4></v-col
            >
          </v-row>
          <div class="first-custom-height">
            <bar-chart
              v-if="impressions_conversions_chart_data"
              :chartdata="impressions_conversions_chart_data"
              :options="conversion_chart_options"
            />
          </div>
        </general-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <h4>Latest Campaigns</h4>
      </v-col>
    </v-row>
    <v-tabs
      v-model="tab"
      slider-color="primary"
      background-color="#F5F7FB"
      class="pt-3"
    >
      <TopBar :tabs="tabs"></TopBar>
      <!-- <main-topbar
            :tabs="tabs"
            :ad_accounts="ad_accounts"
            :_ad_account_id="adAccountId"
            @setAdAccountId="setAdAccountId($event)"
          /> -->
      <v-tab-item value="all">
        <campaigns-table
          :headers="facebook_table_headers"
          :campaigns="facebook_campaigns"
          :ad_accounts="ad_accounts"
          :_ad_account_id="adAccountId"
          @setAdAccountId="setAdAccountId($event)"
        />
      </v-tab-item>
      <v-tab-item value="running">
        <campaigns-table
          :headers="facebook_table_headers"
          :campaigns="running_campaigns"
          :ad_accounts="ad_accounts"
          :_ad_account_id="adAccountId"
          @setAdAccountId="setAdAccountId($event)"
        />
      </v-tab-item>
      <v-tab-item value="paused">
        <campaigns-table
          :headers="facebook_table_headers"
          :campaigns="paused_campaigns"
          :ad_accounts="ad_accounts"
          :_ad_account_id="adAccountId"
          @setAdAccountId="setAdAccountId($event)"
        />
      </v-tab-item>
      <v-tab-item value="finished">
        <campaigns-table
          :headers="facebook_table_headers"
          :campaigns="finished_campaigns"
          :ad_accounts="ad_accounts"
          :_ad_account_id="adAccountId"
          @setAdAccountId="setAdAccountId($event)"
        />
      </v-tab-item>
      <v-tab-item value="draft">
        <campaigns-table
          :headers="facebook_table_headers"
          :campaigns="draft_campaigns"
          :ad_accounts="ad_accounts"
          :_ad_account_id="adAccountId"
          @setAdAccountId="setAdAccountId($event)"
        />
      </v-tab-item>
    </v-tabs>
  </div>
</template>


<script>
import RunningCardsSection from "../../components/Home/RunningCardsSection.vue";
// import TotalCardsSection from "../../components/Home/TotalCardsSection.vue";
import GeneralCard from "../../components/Layouts/Cards/GeneralCard.vue";
import LineChart from "../../components/Charts/LineChart.vue";
import BarChart from "../../components/Charts/BarChart.vue";
import DonutChart from "../../components/Charts/DonutChart.vue";
// import MainTopbar from "../../components/Layouts/Topbars/MainTopbar.vue";
import TopBar from "../../components/Layouts/Topbars/DSPTableTabs.vue";
import CampaignsTable from "../../components/Tables/FacebookCampaignsTable.vue";
import { facebookMixin } from "../../mixins/facebookMixin";

import CampaignOverviewChartFB from "../../components/Home/CampaignOverviewChartFB.vue";
import TodayCardsFacebook from "../../components/Home/TodayCardsFacebook.vue";

export default {
  mixins: [facebookMixin],
  data: () => ({
    unsubmitted_id: "",
    totals_data: [],
    line_chart_data: null,
    adAccountId: "",
    error: null,
    facebook_table_headers: [
      {
        text: "Campaign Name",
        align: "start",
        value: "name",
        width: "20%",
      },
      { text: "Status", value: "status" },
      { text: "Created", value: "created" },
      { text: "Start Date", value: "start" },
      { text: "End Date", value: "end" },
      { text: "Goal", value: "objective" },
      { text: "Bid Strategy", value: "bid_strategy" },
      {
        text: "Budget",
        value: "budget_remaining",
        // width: "130px",
      },
    ],
    ageReach: [
      ["#4F92ED", "16 - 25", 2.2, "up"],
      ["#3371E2", "26 - 35", 0.7, "down"],
      ["#70E09B", "36 - 45", 5.2, "up"],
      ["#F8C76C", "46 - 55", 0.2, "down"],
      ["#E9559A", "Over 55", 1.5, "down"],
    ],
    deviceReach: [
      ["#4F92ED", "Mobile Phone", 2.2, "up"],
      ["#3371E2", "Tablet", 0.7, "down"],
      ["#70E09B", "PC", 5.2, "up"],
      ["#F8C76C", "Smart TV", 0.2, "down"],
      ["#E9559A", "Smart Watch", 1.5, "down"],
    ],
    genderReach: [
      ["#4F92ED", "Male", 2.2, "up"],
      ["#3371E2", "Female", 0.7, "down"],
    ],
    tabs: [
      ["All Campaigns", "all"],
      ["Running", "running"],
      ["Paused", "paused"],
      ["Finished", "finished"],
      ["Draft", "draft"],
    ],
    date_store: [],
    impressions_store: [],
    clicks_store: [],
    cpc_store: [],
    age_range: [],
    reach_store: [],
    //Chart Inits
    campaigns_clicks_chart_data: null,
    campaigns_impressions_chart_data: null,
    age_chart_data: null,
    impressions_conversions_chart_data: null,

    // Impression Chart Options
    impressions_chart_options: {
      responsive: true,
      maintainAspectRatio: false,
      tooltips: {
        mode: "point",
      },
      elements: {
        point: {
          radius: 0,
        },
      },
      scales: {
        yAxes: [
          {
            gridLines: {
              color: "rgba(0, 0, 0, 0)",
            },
            ticks: {
              beginAtZero: true,
              padding: 5,
              callback: (label) => `${label} K`,
            },
          },
          {
            gridLines: {
              color: "rgba(0, 0, 0, 0)",
            },
            ticks: {
              beginAtZero: true,
              padding: 5,
              stepSize: 2,
            },
            position: "right",
          },
        ],
        xAxes: [
          {
            ticks: { fontStyle: "bold" },
            display: true,
          },
        ],
      },
    },

    // Clicks Chart Options
    clicks_chart_options: {
      responsive: true,
      maintainAspectRatio: false,
      tooltips: {
        mode: "point",
      },
      elements: {
        line: {
          tension: 0,
        },
        point: {
          radius: 0,
        },
      },
      legend: {
        display: false,
      },
      scales: {
        yAxes: [
          {
            gridLines: {
              color: "rgba(0, 0, 0, 0)",
            },
            ticks: {
              padding: 5,
            },
            scaleLabel: {
              display: true,
              labelString: "Clicks",
              fontColor: "#000",
              fontStyle: "bold",
            },
          },
          {
            position: "right",
            gridLines: {
              color: "rgba(0, 0, 0, 0)",
            },
            ticks: {
              padding: 5,
              beginAtZero: true,
            },
            scaleLabel: {
              display: true,
              labelString: "CPC",
              fontColor: "#000",
              fontStyle: "bold",
            },
          },
        ],
        xAxes: [
          {
            ticks: { fontStyle: "bold" },
          },
        ],
      },
    },

    // Age Chart Options
    age_chart_options: {
      maintainAspectRatio: false,
      responsive: true,
      scale: {
        display: false,
      },
    },

    // Conversion CHart Options
    conversion_chart_options: {
      datasets: {
        bar: {
          barPercentage: 3,
          categoryPercentage: 0.3,
        },
      },
      scales: {
        xAxes: [
          {
            stacked: true,
            barPercentage: 5,
            gridLines: {
              display: false,
            },
            ticks: {
              // autoSkip: true,
              // maxTicksLimit: 20,
              // maxRotation: 0,
              // minRotation: 0,
              fontColor: "#000",
              fontStyle: "bold",
            },
          },
        ],
        yAxes: [
          {
            id: "clicks",
            position: "left",
            ticks: {
              stepSize: 1000,
              min: 1000,
              // callback: (label) => `${label} K`,
            },
            scaleLabel: {
              display: true,
              labelString: "Clicks",
              fontColor: "#000",
              fontStyle: "bold",
            },
          },
          {
            id: "cost",
            position: "right",
            ticks: {
              stepSize: 1000,
              min: 1000,
              callback: (label) => label,
            },
            scaleLabel: {
              display: true,
              labelString: "Impressions",
              fontColor: "#000",
              fontStyle: "bold",
            },
            gridLines: {
              display: false,
            },
          },
        ],
      },
      maintainAspectRatio: false,
      legend: false,
    },
  }),
  components: {
    RunningCardsSection,
    // TotalCardsSection,
    GeneralCard,
    LineChart,
    BarChart,
    // MainTopbar,
    TopBar,
    CampaignsTable,
    DonutChart,
    CampaignOverviewChartFB,
    TodayCardsFacebook,
  },
  methods: {
    percentageColor(status) {
      if (status === "up") return "dashboard-green ml-2";
      if (status === "down") return "dashboard-red ml-2";
    },
    generateImpressionsChartReport() {
      this.impressions_store = Object.values(this.general_facebook_reports).map(
        (impressions) => parseInt(impressions.total_impressions) / 1000
      );

      this.campaigns_impressions_chart_data = {
        labels: this.date_store,
        datasets: [
          {
            label: "Impressions",
            data: this.impressions_store,
            fill: true,
            backgroundColor: "rgba(69, 176, 255,.5)",
            borderColor: "rgb(79,146,237)",
            borderWidth: 2,
          },
        ],
      };
    },
    generateClicksCTRChartReport() {
      this.clicks_store = Object.values(this.general_facebook_reports).map(
        (clicks) => parseInt(clicks.total_clicks)
      );
      this.cpc_store = Object.values(this.general_facebook_reports).map((cpc) =>
        parseInt(cpc.total_cpc)
      );

      const modified_cpc_store = this.cpc_store.map(
        (cpc) => parseInt(cpc) * 1000
      );

      this.campaigns_clicks_chart_data = {
        labels: this.date_store,
        datasets: [
          {
            data: this.clicks_store,
            fill: false,
            borderColor: "rgb(97,156,238)",
            borderWidth: 2,
          },
          {
            data: modified_cpc_store,
            fill: false,
            borderColor: "rgb(255,157,24)",
            borderWidth: 3,
          },
        ],
      };
    },
    generateImpressionsConversionsReport() {
      const converted_clicks = this.clicks_store.map((clicks) =>
        parseInt(clicks)
      );
      this.impressions_conversions_chart_data = {
        labels: this.date_store,
        datasets: [
          {
            label: "Clicks",
            data: converted_clicks,
            backgroundColor: "rgb(248, 199, 108)",
            borderWidth: 0,
            yAxisID: "clicks",
            stack: "2",
            order: 2,
          },
          {
            label: "Impressions",
            data: this.impressions_store,
            backgroundColor: "rgb(79, 146, 237)",
            borderWidth: 0,
            stack: "1",
            order: 1,
            yAxisID: "cost",
          },
        ],
      };
    },
  },
  async mounted() {
    this.apexLoading = true;
    // this.totals_data = this.api.home.totals_programmatic;
    // this.line_chart_data = this.api.averageCPMData.totals_programmatic;
    this.adAccountId = this.latest_ad_account
      ? this.latest_ad_account.id.substring(4)
      : "";

    //Impressions Graph Data
    try {
      const group_by = "date_start";
      this.$store.dispatch("facebook/getFacebookReports", group_by);
    } catch (error) {
      this.error = error
    }

    try {
      const group_by = "age";
      this.$store.dispatch("facebook/getFacebookReports", group_by);
    } catch (error) {
      this.error = error
    }

    try {
      this.$store.dispatch("facebook/getCardsInsights");
    } catch (error) {
      this.error = error
    }

    //Disapproved Banners
    try {
      this.$store.commit("makingApiRequest", true);
      await this.$store.dispatch("dsp/getDisapCreative");
      this.$store.commit("makingApiRequest", false);
    } catch (error) {
     this.error = error
      this.$store.commit("makingApiRequest", false);
    }

    // Get Adaccounts
    try {
      this.$store.dispatch("facebook/getFacebookAdaccounts");
    } catch (error) {
      this.error = error
    }
  },

  computed: {
    superAdmin() {
      return this.$store.getters.superAdmin;
    },
    general_facebook_reports() {
      return this.$store.state.facebook.general_facebook_reports;
    },
    general_facebook_age_report() {
      return this.$store.state.facebook.general_facebook_age_report;
    },
    facebook_cards_insights() {
      return this.$store.state.facebook.facebook_cards_insights;
    },
    dsp_creatives() {
      return this.$store.state.dsp.dsp_creatives;
    },
    disapproved_creatives() {
      return this.$store.state.dsp.disapproved_creatives;
    },
    unsubmitted_creatives() {
      return this.$store.state.dsp.unsubmitted_creatives;
    },
  },

  general_facebook_reports() {
    const options = { day: "numeric", month: "short", year: "numeric" };
    this.date_store = Object.keys(this.general_facebook_reports).map((date) =>
      new Date(date).toLocaleDateString("en-US", options)
    );

    this.generateImpressionsChartReport();
    this.generateClicksCTRChartReport();
    this.generateImpressionsConversionsReport();
  },
  general_facebook_age_report() {
    this.age_range = Object.keys(this.general_facebook_age_report);
    this.reach_store = Object.values(this.general_facebook_age_report).map(
      (reach) => reach.total_reach
    );

    this.age_chart_data = {
      labels: this.age_range,
      datasets: [
        {
          data: this.reach_store,
          backgroundColor: [
            "#70df9b",
            "#3271e2",
            "#ffcd56",
            "#ff6484",
            "#36a2eb",
          ],
        },
      ],
    };
  },
};
</script>

<style>
#all,
#running,
#finished,
#draft,
#billing {
  background-color: #f5f7fb !important;
}
