<template>
  <v-row class="mt-3">
    <v-col md="3" cols="12">
      <v-card elevation="0" outlined>
        <div class="d-flex align-start">
          <div class="mr-auto">
            <v-card-title class="display-1 " >{{running_campaigns.length}}</v-card-title>
            <v-card-subtitle class="overline grey--text lighten-5">Running</v-card-subtitle>
          </div>
          <v-icon class="ma-4" color="primary" >mdi-play</v-icon>
        </div>   
      </v-card>
    </v-col>
    <v-col md="3" cols="12">
            <v-card elevation="0" outlined>
                <div class="d-flex align-start">
                  <div class="mr-auto">
                    <v-card-title class="display-1">{{paused_campaigns.length}}</v-card-title>
                    <v-card-subtitle class="overline grey--text lighten-5">Paused</v-card-subtitle>
                  </div>
                <v-icon class="ma-4" color="primary" >mdi-pause</v-icon>
              </div> 
            </v-card>
          </v-col>
          <v-col md="3" cols="12">
            <v-card flat outlined>
                <div class="d-flex align-start">
                  <div class="mr-auto">
                    <v-card-title class="display-1">{{draft_campaigns.length}}</v-card-title>
                    <v-card-subtitle class="overline grey--text lighten-5">draft</v-card-subtitle>
                  </div>
                <v-icon class="ma-4" color="primary" >mdi-clipboard-edit</v-icon>
              </div> 
            </v-card>
          </v-col>
          <v-col md="3" cols="12">
            <v-card flat outlined>
              <div class="d-flex align-start">
                  <div class="mr-auto">
                    <v-card-title class="display-1">{{finished_campaigns.length}}</v-card-title>
                    <v-card-subtitle class="overline grey--text lighten-5">Finished</v-card-subtitle>
                  </div>
                <v-icon class="ma-4" color="primary" >mdi-check-all</v-icon>
              </div> 
            </v-card>
          </v-col>
        
  </v-row>
</template>

<script>
export default {
  props: {
    running_campaigns: Array,
    paused_campaigns: Array,
    draft_campaigns: Array,
    finished_campaigns: Array,
  },
  methods: {
    getIconColor(icon) {
      if (icon === "mdi-facebook") {
        return "#3b5998";
      } else if (icon === "mdi-twitter") {
        return "#00acee";
      } else {
        return "#4F92ED";
      }
    },
  },

};
</script>


