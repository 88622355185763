<template>
  <v-dialog v-model="dialog" width="800" light overlay-opacity="0.5" persistent>
    <v-card>
      <v-toolbar class="pl-0" dark color="primary">
        <v-toolbar-title>
          {{ item.name }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon @click="dismiss">
            <v-icon>mdi-close</v-icon>
          </v-btn></v-toolbar-items
        >
      </v-toolbar>

      <v-simple-table v-if="banners.length" fixed-header height="400px">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Index</th>
              <th class="text-left">ID</th>
              <th class="text-left">Name</th>
              <th class="text-left">Size</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="banner in banners" :key="banner.id">
              <td>
                {{ banners.indexOf(banner) + 1 }}
              </td>
              <td>
                {{ banner.id }}
              </td>
              <td>{{ banner.name }}</td>
              <td>{{ banner.dimensions }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-card-text v-else>
        <div class="text-center">Campaign does not have banners</div>
      </v-card-text>

      <v-card-actions class="pa-4">
        <v-spacer></v-spacer>
        <v-btn text @click="dismiss"> Cancel </v-btn>
        <v-btn
          v-if="banners.length"
          @click="submit"
          color="primary"
          depressed
          class="ml-2"
          >Submit All Banners</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      banners: [],
    };
  },
  watch: {
    item() {
      this.banners = this.item.banners;
    },
  },
  methods: {
    submit() {
      this.$emit("submitBanners", this.item.campaign_id);
    },
    dismiss() {
      this.$emit("dismiss");
      this.banners = [];
    },
  },
};
</script>
