<template>
  <div>
    <v-row class="mt-5">
      <v-col lg="3" md="6" cols="12">
        <v-card outlined>
          <v-row no-gutters>
            <v-col cols="12">
              <v-card-title class="display-1">
                {{ todaysReach.toLocaleString("en") }}
              </v-card-title>
              <v-card-subtitle class="overline grey--text lighten-5">
                Reach Today
              </v-card-subtitle>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col lg="3" md="6" cols="12">
        <v-card outlined>
          <v-row no-gutters>
            <v-col cols="12">
              <v-card-title class="display-1">
                ${{ todaystotalSpend.toLocaleString("en") }}
              </v-card-title>
              <v-card-subtitle class="overline grey--text lighten-5">
                Spend Today
              </v-card-subtitle>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col lg="3" md="6" cols="12">
        <v-card outlined>
          <v-row no-gutters>
            <v-col cols="12">
              <v-card-title class="display-1">
                {{ todaystotalClicks.toLocaleString("en") }}
              </v-card-title>
              <v-card-subtitle class="overline grey--text lighten-5">
                Clicks Today
              </v-card-subtitle>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col lg="3" md="6" cols="12">
        <v-card outlined>
          <v-row no-gutters>
            <v-col cols="12">
              <v-card-title class="display-1">
                {{ todaystotalCTR }}%
              </v-card-title>
              <v-card-subtitle class="overline grey--text lighten-5">
                Avg.CTR Today
              </v-card-subtitle>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      todaystotalImpressions: 0,
      todaystotalSpend: 0,
      todaystotalCTR: 0,
      todaystotalClicks: 0,
      todaysReach: 0,
    };
  },

  mounted() {
    //Today's Data is last value in returned reports

    let today = Object.entries(this.general_facebook_reports).pop();

    //Convert to mm/dd/yy
    let returnedStatsDate = new Date(today[0])
      .toLocaleString("en")
      .split(",")[0];

    //Get day today

    let dateToday = new Date();

    //Convert to mm/dd/yy
    let todayConverted = dateToday.toLocaleString("en").split(",")[0];

    let yesterday = ( d => new Date(d.setDate(d.getDate()-1)) )(new Date).toLocaleString("en").split(",")[0];

    //Check if the last date in general reports is today , if it is return the  values, If not return 0;

    if (returnedStatsDate === todayConverted || returnedStatsDate === yesterday ) {
      let today_data = today.pop();
      this.todaystotalImpressions = today_data.total_impressions;
      this.todaystotalClicks = today_data.total_clicks;
      this.todaystotalCTR = today_data.average_ctr.toFixed(3);
      this.todaystotalSpend = today_data.total_spend;
      this.todaysReach = today_data.total_reach;
    }
  },

  props: {
    general_facebook_reports: Object,
  },
};
</script>