<template>
  <v-row class="mt-3">
    <v-col cols="12">
      <v-card outlined>
        <v-card-title class="d-flex align-center py-2">
          <div class="d-flex">
            <h5>Campaigns Overview</h5>
          </div>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <total-cards-section
            :facebook_cards_insights="facebook_cards_insights"
            v-if="facebook_cards_insights"
            @toggleSeries="toggleSeries"
            @onClickCheck="onclick($event)"
          />
        </v-card-text>
        <v-card-text>
          <v-row>
            <v-col cols="2"
              ><v-select
                class="shrink"
                outlined
                dense
                label="Metric"
                @change="selectSeries($event)"
                v-model="select_series"
                :items="select_charts"
                hide-details
                :menu-props="{ bottom: true, offsetY: true }"
              ></v-select>
            </v-col>
            <v-col cols class="pl-0 pr-0 shrink">
              <div class="d-flex align-center text-center">
                <div class="mt-2">vs</div>
              </div>
            </v-col>
            <v-col cols="2"
              ><v-select
                class="shrink"
                outlined
                dense
                label="Select a Metric"
                placeholder="Select a Metric"
                @change="selectSeries2($event)"
                v-model="select_series2"
                :items="select_charts2"
                hide-details
                :menu-props="{ bottom: true, offsetY: true }"
              ></v-select>
            </v-col>
            <v-col cols="2">
              <v-select
                class="shrink"
                outlined
                dense
                return-object
                item-text="text"
                item-value="value"
                label="Period"
                @change="updateDateRange($event)"
                v-model="defaultChartDateRange"
                :items="chartDateRange"
                hide-details
                :menu-props="{ bottom: true, offsetY: true }"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <apexchart
                type="line"
                height="300"
                v-if="apexLoading"
                :options="lineChartOptions"
                :series="series"
                ref="chart"
              ></apexchart>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import config from "@/apex-config";
import TotalCardsSection from "./TotalCardsSection.vue";

export default {
  components: {
    TotalCardsSection,
  },
  data() {
    return {
      config,

      apexLoading: false,
      select_series: "Reach",
      select_series2: "",
      select_charts: ["Reach", "Spend", "Clicks", "CTR"],
      select_charts2: ["Reach", "Spend", "Clicks", "CTR"],
      defaultChartDateRange: { text: "All Days", value: "all" },
      chartDateRange: [
        { text: "Last 7 Days", value: 7 },
        { text: "Last 30 Days", value: 30 },
        { text: "All Days", value: "all" },
      ],
      lineChartOptions: {
        chart: {
          type: "line",
          height: 300,
          zoom: {
            enabled: true,
          },
          toolbar: {
            show: false,
          },
          animation: {
            enabled: false,
          },
        },

        noData: {
          text: "No Data...",
          align: "center",
          verticalAlign: "middle",
        },
        // theme: {
        //   palette: "palette1", // upto palette10
        // },
         colors: [
          config.light.primary,
          config.light.error,
        ],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: [2, 2],
          dashArray: [0, 0],
          curve: ["straight", "straight"],
        },
     
        markers: {
          size: 3,
          style: "hollow",
          hover: {
            sizeOffset: 4,
          },
        },

        yaxis: [
          {
            opposite: false,
            show: true,
            showForNullSeries: false,
            decimalsInFloat: 2,
            // seriesName: "Impressions",
            // title: {
            //   text: "Impressions",
            // },
            // labels: {
            //   formatter: (val) => {
            //     return val ? val.toFixed(2) : val;
            //   },
            // },
            // min: 500,
          },
          {
            opposite: true,
            show: true,
            showForNullSeries: false,
            decimalsInFloat: 2,
            // seriesName: "Spend",
            // title: {
            //   text: "Spend",
            // },
            // labels: {
            //   formatter: (val) => {
            //     return val
            //       ? val.toLocaleString("en-us", {
            //           style: "currency",
            //           currency: "USD",
            //         })
            //       : val;
            //   },
            // },
          },
        ],

        legend: {
          opposite: false,
          show: true,
          showForNullSeries: false,
          position: "top",
          horizontalAlign: "left",
          itemMargin: {
            horizontal: 5,
            vertical: 5,
          },
        },
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
          },
        },
      },
      series: [],
    };
  },
  mounted() {
    setTimeout(() => {
      this.apexLoading = true;
      this.generateChartData();
    });
  },

  watch: {
    general_facebook_reports() {
      this.generateChartData();
    },
  },

  computed: {
    general_facebook_reports() {
      //sort data by dates
      return this.$store.state.facebook.general_facebook_reports;
    },
    facebook_cards_insights() {
      return this.$store.state.facebook.facebook_cards_insights;
    },
  },
  methods: {
    updateDateRange(timeline) {
      if (timeline === "last_7") {
        this.$refs.chart.zoomX(
          new Date(this.chartDates.slice(-7)[0]).getTime(),
          new Date(this.chartDates.slice(-1)).getTime()
        );
      } else if (timeline === "last_30") {
        this.$refs.chart.zoomX(
          new Date(this.chartDates.slice(-30)[0]).getTime(),
          new Date(this.chartDates.slice(-1)).getTime()
        );
      } else if (timeline === "all") {
        this.$refs.chart.zoomX(
          new Date(this.chartDates[0]).getTime(),
          new Date(this.chartDates.slice(-1)).getTime()
        );
      }
    },
    getSeries() {
      const selectedSeries = this.series.find((series) => {
        return series.name == this.select_series;
      });

      return selectedSeries;
    },
    getSeries2() {
      if (this.select_series2) {
        const selectedSeries = this.series.find((series) => {
          return series.name == this.select_series2;
        });

        return selectedSeries;
      } else return { name: "", data: [] };
    },
    selectSeries() {
      this.updateChart();
    },
    selectSeries2() {
      this.updateChart();
    },
    updateChart() {
      if(this.select_series == this.select_series2) {
        this.$refs.chart.updateSeries([
        { name: this.getSeries().name, data: this.getSeries().data },
        { name: "", data:[] },
      ]);
      } else
      this.$refs.chart.updateSeries([
        { name: this.getSeries().name, data: this.getSeries().data },
        { name: this.getSeries2().name, data: this.getSeries2().data },
      ]);
    },

    generateChartData() {
      const unsorted = this.general_facebook_reports;
      const sorted = Object.keys(unsorted)
        .sort()
        .reduce((obj, key) => {
          obj[key] = unsorted[key];
          return obj;
        }, {});

      this.monthlyImpressions = Object.values(sorted).map((value) =>
        parseInt(value.total_impressions)
      );
      this.monthlyClicks = Object.values(sorted).map((value) =>
        parseInt(value.total_clicks)
      );
      this.monthlySpend = Object.values(sorted).map((value) =>
        Number.parseFloat(value.total_spend).toFixed(3)
      );
      this.monthlyAvgCTR = Object.values(sorted).map((value) =>
        Number.parseFloat(value.average_ctr).toFixed(3)
      );

      this.chartDates = Object.keys(sorted);

      this.series = [
        {
          name: "Reach",
          data: this.monthlyImpressions,
        },
        {
          name: "Spend",
          data: this.monthlySpend,
        },
        {
          name: "Clicks",
          data: this.monthlyClicks,
        },
        {
          name: "CTR",
          data: this.monthlyAvgCTR,
        },
      ];
      this.lineChartOptions = {
        ...this.lineChartOptions,
        ...{
          xaxis: {
            type: "datetime",
            categories: this.chartDates,
          },
        },
      };
      setTimeout(() => {
        this.selectSeries();
        this.selectSeries2();
        this.updateDateRange(this.defaultChartDateRange);
      });
    },
  },
};
</script>
