<template>
  <v-card outlined id="campaign">
    <ConfirmDelete
      :dialog="dialog"
      :item="mine"
      @delete="rundeleteFn($event)"
      @dismiss="dismissFn($event)"
    />

    <showBanners
      :dialog="modalShow"
      :item="mine"
      @submitBanners="submitCampaignBanners($event)"
      @dismiss="dismissFn($event)"
    />

    <v-card-title class="d-flex align-center">
      <v-btn @click="redirectToCreateCampaign" depressed color="primary">
        <v-icon left> mdi-plus </v-icon>
        Create Campaign
      </v-btn>

      <v-spacer></v-spacer>
      <v-row align="center" justify="end" no-gutters>
        <v-col cols="4" class="ml-3">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            outlined
            dense
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="4" class="ml-3">
          <v-select
            outlined
            dense
            append-icon="mdi-chevron-down"
            v-model="selected_ad_account"
            @change="updateAdAccount"
            label="Select Ad Account"
            return-object
            :items="ad_accounts"
            :menu-props="{ bottom: true, offsetY: true }"
            item-text="name"
            hide-details
            flat
          ></v-select>
        </v-col>
      </v-row>
    </v-card-title>
    <v-divider></v-divider>
    <v-data-table
      v-if="campaigns"
      :headers="headers"
      :items="campaigns"
      :search="search"
      :page.sync="page"
      hide-default-footer
      :items-per-page="itemsPerPage"
      @page-count="pageCount = $event"
      @click:row="viewReport"
      :loading="loading"
      loading-text="Loading... Please wait"
    >
      <!-- eslint-disable-next-line -->

      <!-- eslint-disable-next-line -->
      <template v-slot:item.name="{ item }">
        <span class="font-weight-bold">
          <router-link
            :to="{
              name: 'facebook-campaign',
              params: {
                id: item.id,
                account_id: item.account_id
              },
            }"
          >
            {{ item.name }}
          </router-link>
        </span>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.status="{ item }">
        <v-chip
          v-if="item.status === null"
          color="grey lighten-5"
          text-color="grey"
          class="font-weight-bold"
          small
        >
          <v-icon x-small left>mdi-circle</v-icon>Incomplete
        </v-chip>
        <v-chip
          v-else-if="item.status == 'ACTIVE'"
          color="green lighten-5"
          text-color="success"
          class="font-weight-bold"
          small
        >
          <v-icon x-small left>mdi-circle</v-icon>Running
        </v-chip>
        <v-chip
          v-else-if="item.status == 'DRAFT'"
          color="blue lighten-5"
          text-color="primary"
          class="font-weight-bold"
          small
        >
          <v-icon x-small left>mdi-circle</v-icon>
          <span class="text-capitalize">{{ item.status.toLowerCase() }}</span>
        </v-chip>
        <v-chip
          v-else-if="item.status == 'finished'"
          color="cyan lighten-5"
          text-color="cyan"
          class="font-weight-bold"
          small
        >
          <v-icon x-small left>mdi-circle</v-icon>
          <span class="text-capitalize">{{ item.status.toLowerCase() }}</span>
        </v-chip>
        <v-chip
          v-else-if="item.status == 'PAUSED'"
          color="orange lighten-5"
          text-color="warning"
          class="font-weight-bold"
          small
        >
          <v-icon x-small left>mdi-circle</v-icon>
          <span class="text-capitalize">{{ item.status.toLowerCase() }}</span>
        </v-chip>
        <v-chip
          v-else
          color="red lighten-5"
          text-color="error"
          class="font-weight-bold"
          small
        >
          <v-icon x-small left>mdi-circle </v-icon
          ><span class="text-capitalize">{{ item.status.toLowerCase() }}</span>
        </v-chip>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.created="{ item }">
        {{ new Date(item.created_time).toLocaleDateString() }}
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.start="{ item }">
        {{ new Date(item.start_time).toLocaleDateString() }}
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.end="{ item }">
        <span v-if="item.stop_time">
          {{ new Date(item.stop_time).toLocaleDateString() }}
        </span>
        <span v-else>N/A</span>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.objective="{ item }">
        <span class="text-capitalize">
          {{ styleString(item.objective).toLowerCase() }}</span
        >
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.bid_strategy="{ item }">
        <span v-if="item.bid_strategy" class="text-capitalize">
          {{ styleString(item.bid_strategy).toLowerCase() }}</span
        >
        <span v-else>None</span>
      </template>

      <!-- eslint-disable-next-line -->
      <!-- <template v-slot:item.budget="{ item }">
        <span>${{ item.budget }}</span>
      </template> -->

      <!-- eslint-disable-next-line -->
      <!-- <template v-slot:item.budget_remaining="{ item }">
        <span>${{ (item.budget_remaining / 100).toFixed(2) }}</span>
      </template> -->
    </v-data-table>
    <v-divider></v-divider>
    <v-card-actions class="d-flex justify-space-between align-center pa-4">
      <div class="caption">Page: {{ page }} of {{ pageCount }}</div>
      <v-pagination
        v-model="page"
        :length="pageCount"
        color="primary"
        class="custom"
      ></v-pagination>
    </v-card-actions>
  </v-card>
</template>

<script>
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import ConfirmDelete from "../ConfirmDelete.vue";
import showBanners from "../Programmatic/ShowCampaignBannersModal.vue";

let date = new Date();
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default {
  props: {
    campaigns: {
      type: Array,
    },
    paginated_campaigns: {
      type: Object,
      default: null,
    },
    headers: {
      type: Array,
    },
    loading: Boolean,
    advertisers: {
      type: Array,
    },
    agencies: {
      type: Array,
    },
    fetched_advertisers: {
      type: Array,
    },
    dsp_pagination: {
      type: Object,
    },
    ad_account: Object,
    ad_accounts: Array,
  },
  components: {
    ConfirmDelete,
    showBanners,
  },
  data() {
    return {
      dialog: false,
      modalShow: false,
      mine: {},
      ad_account_id: null,
      selected_ad_account: null,
      selected_agency: null,
      selected_advertiser: null,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      deals_filters: ["Preferred Deal", "Private Auction"],
      deals_filter: "",
      youtube_filters: ["Ended", "Eligible"],
      youtube_filter: "",
      show: false,
      date: date,
      isFacebook: false,
      isDirectDeals: false,
      isYoutube: false,
      isHome: false,
      isFacebookDb: false,
      search: "",
      agency: "",
      campaign: "",
      advertiser: "",
      display: 10,
      items: [10, 25, 50, 200, 250],
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [1, 5, 10, 25, 50],
        total: 1,
        to: 1,
        from: 1,
        firstPage: 1,
        lastPage: 1,
        nextPage: 0,
        previousPage: 0,
      },
      filter_params: {
        per_page: 10,
        page: 1,
      },
    };
  },
  methods: {
    updateAdAccount() {
      let id = this.selected_ad_account.id;

      this.ad_account_id = id;

      localStorage.setItem(
        "ad_account",
        JSON.stringify(this.selected_ad_account)
      );

      this.$emit("setAdAccountId", this.ad_account_id);
    },
    styleString(data) {
      return data.replaceAll("_", " ");
    },
    redirectToCreateCampaign() {
      this.$router.push("/facebook/create-campaign");
    },
    emitAdAccountId() {
      this.$emit("setAdAccountId", this.ad_account_id.substring(4));
    },
    handlePageChange(value) {
      this.dsp_pagination.page = value;
      this.filter_params.page = value;
      this.$emit("triggerPageChange", value);
    },
    viewReport(item) {
      this.$emit("viewReport", item.id);
    },
    isDsp() {
      return this.$route.name === "campaigns";
    },
    async showBanners(item) {
      this.modalShow = !this.modalShow;
      this.mine = await item;
    },
    submitCampaignBanners(campaign_id) {
      this.$emit("submitBanners", campaign_id);
    },
    rundeleteFn(id) {
      // Delete campaign
      this.$emit("deleteCampaign", id);
    },
    dismissFn() {
      this.dialog = false;
      this.modalShow = false;
    },
    async openModal(item) {
      this.dialog = !this.dialog;
      // this.modalOpen = true;
      this.mine = await item;
    },
    setStatusColor(status) {
      if (status === "WITH_ISSUES") return "error";
      if (status === "rejected") return "error";
      if (status === "suspended") return "error";
      if (status === "IN_PROCESS") return "warning";
      if (status === "waiting") return "warning";
      if (status === "PAUSED") return "warning";
      if (status === "ACTIVE") return "success";
      if (status === "running") return "success";
      if (status === "runnable") return "success";
      if (status === "ARCHIVED") return "secondary";
      if (status === "finished") return "secondary";
      if (status === "Eligible") return "success";
      if (status === "Ended") return "secondary";
      if (status === "offline") return "secondary";
    },
    setClass(status) {
      if (status === "WITH_ISSUES") return "error--text";
      if (status === "IN_PROCESS") return "warning--text";
      if (status === "PAUSED") return "warning--text";
      if (status === "ACTIVE") return "success--text";
      if (status === "ARCHIVED") return "success-text";
    },

    generate() {
      let docDefinition = {
        content: [
          {
            text: "CAMPAIGNS",
            fontSize: 20,
            bold: true,
            alignment: "center",
            decoration: "underline",
            color: "skyblue",
          },
          {
            text: "Disclaimer!",
            style: "sectionHeader",
          },
          {
            columns: [
              [{ text: "This is live data." }],
              [
                {
                  text: `Date: ${new Date().toLocaleString()}`,
                  alignment: "right",
                },
                {
                  text: `Serial : ${(Math.random() * 1000).toFixed(0)}`,
                  alignment: "right",
                },
              ],
            ],
          },
          {
            columns: [
              [
                {
                  qr: "Mediapal",
                  fit: "50",
                  style: "sectionHeader",
                },
              ],
            ],
          },
          {
            table: {
              headerRows: 1,
              widths: ["*", "auto", "auto", "auto"],
              body: [
                ["Ad", "Status", "Objective", "Amount Remaining"],
                ...this.campaigns.map((c) => [
                  c.name,
                  c.effective_status,
                  c.objective,
                  c.budget_remaining,
                ]),
                [{ text: "Total Amount", colSpan: 3 }, {}, {}, {}],
              ],
            },
          },
        ],
        styles: {
          sectionHeader: {
            bold: true,
            decoration: "underline",
            fontSize: 14,
            margin: [0, 15, 0, 15],
          },
        },
      };
      pdfMake.createPdf(docDefinition).print();
    },
  },
  async mounted() {
    this.$route.name == "facebook"
      ? (this.isFacebook = true)
      : this.$route.name == "direct-deals"
      ? (this.isDirectDeals = true)
      : this.$route.name == "youtube-campaigns"
      ? (this.isYoutube = true)
      : null;

    this.$route.name == "home" ? (this.isHome = true) : null;
    this.$route.name == "Facebook Dashboard"
      ? (this.isFacebookDb = true)
      : null;

    //check if local storage has ad account
    if (localStorage.getItem("ad_account")) {
      // this.adAccountId = JSON.parse(localStorage.getItem("ad_account"));
      this.selected_ad_account = JSON.parse(localStorage.getItem("ad_account"));
    }
  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    currency() {
      let data = this.$store.state.ad_account;
      if (data) {
        return data.currency;
      }
    },
    superAdmin() {
      return this.$store.getters.superAdmin;
    },
  },
  watch: {
    ad_account() {
      this.selected_ad_account = this.ad_account;
    },

    selected_agency() {
      this.$emit("filterByAgency", this.selected_agency);
    },
    selected_advertiser() {
      this.$emit("filterByAdvertiser", this.selected_advertiser);
    },
    filter_params: {
      handler: function () {
        this.itemsPerPage = this.filter_params.per_page;
        this.$emit("filterParams", this.filter_params);
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.custom {
  width: auto;
  justify-items: center;
}

.custom .v-pagination__navigation {
  height: 24px !important;
  width: 24px !important;
}

.custom .v-pagination__navigation .v-icon {
  font-size: 16px !important;
}

.custom .v-pagination__item {
  height: 24px !important;
  min-width: 24px !important;
  font-size: 0.65rem !important;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  border-bottom: none !important;
}

.general-table
  .v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > td {
  color: #707070;
}

.custom-cursor {
  cursor: pointer;
}

.custom-cursor:hover {
  font-size: 16px;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 0.7rem !important;
}

#campaign .v-input__icon {
  height: 30px !important;
  /* width: 38px !important; */
}

.v-input__icon {
  height: 30px !important;
  width: 0;
}

.v-text-field fieldset {
  border-color: rgba(154, 154, 154, 0.2) !important;
}

.v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined
  .v-input__append-inner {
  margin: 0 !important;
}
</style>
