<template>
	<div>
		<v-row>
			<v-col lg="3" md="6" cols="12"> 
				<v-card flat color="grey lighten-5">
					<v-card-title class="display-1">
						{{ _totalReach }}
					</v-card-title>
					<v-card-subtitle class="overline">
						Total Reach
					</v-card-subtitle>
				</v-card>
			</v-col>
			<v-col lg="3" md="6" cols="12">
				<v-card flat color="grey lighten-5">
					<v-card-title class="display-1">
						{{ _totalSpend }}
					</v-card-title>
					<v-card-subtitle class="overline">
						Total Spend
					</v-card-subtitle>
				</v-card>
			</v-col>
			<v-col lg="3" md="6" cols="12">
				<v-card flat color="grey lighten-5">
					<v-card-title class="display-1">
						{{ _totalClicks }}
					</v-card-title>
					<v-card-subtitle class="overline">
						Total Clicks
					</v-card-subtitle>
				</v-card>
			</v-col>
			<v-col lg="3" md="6" cols="12">
				<v-card flat color="grey lighten-5">
					<v-card-title class="display-1">
						{{ facebook_cards_insights.average_ctr.toLocaleString("US") }}%
					</v-card-title>
					<v-card-subtitle class="overline ">
						Total CTR
					</v-card-subtitle>
				</v-card>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import { compactNumber, usCurrencyFormatterCompact } from "@/utils/numberFormatter.js";

export default {
	props: {
		facebook_cards_insights: Object,
	},
	data() {
		return {

		};
	},
	computed: {
		_totalReach() {
			return compactNumber(this.facebook_cards_insights.total_reach);
		},
		_totalSpend() {
			return usCurrencyFormatterCompact(this.facebook_cards_insights.total_spend);
		},
		_totalClicks() {
			return compactNumber(this.facebook_cards_insights.total_clicks);
		},
	},

};
</script>
